import React, { CSSProperties } from "react";
import QuickLinkItem from "./quickLinkItem";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { CustomCard } from "../common/customCard";

const QuickLinks = () => {
  const { isAuthenticated, user } = useSelector(
    (state: RootState) => state.userReducer
  );

  return (
    <>
      <br />
      <CustomCard headerText="Quick Links">
        <div
          className="row mb-3 centeredColumn"
          style={{ alignSelf: "center" }}
        >
          <QuickLinkItem name="Forms" icon="book-open" link="Forms" />
          <QuickLinkItem
            name="Procedural Documents"
            icon="certificate"
            link="Procedures"
          />
          <QuickLinkItem
            name="Help"
            icon="question-circle"
            link="/ContactUs"
          />
        </div>
        {isAuthenticated && (
          <div className="row mb-3 centeredColumn">
            <QuickLinkItem
              name="Procedural Documents Review"
              icon="cloud-upload-alt"
              link="Reviews"
            />
            <QuickLinkItem
              name="Request for Form Action"
              icon="location-arrow"
              link="request-form-actions"
            />
            <QuickLinkItem
              name="Document Contact Change"
              icon="recycle"
              link="document-contact-changes"
            />
          </div>
        )}
      </CustomCard>
    </>
  );
};

export default QuickLinks;
