import React, { useState, CSSProperties, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../redux/rootReducer";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavLink,
} from "reactstrap";
import FDOTLogo from "./FDOTLogo.png";
import * as configApi from "../apis/configApi";
import { IClientConfig } from "../models/IClientConfig";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "../utils/initializer";
import localforage from "localforage";

const Header = () => {
  const dropDownMenuItemStyle: CSSProperties = {
    color: "black",
  };

  const headerTextStyle: CSSProperties = {
    color: "white",
    padding: "10px",
  };

  const brandStyle: CSSProperties = {
    paddingRight: 20,
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const { isAuthenticated, user } = useSelector(
    (state: RootState) => state.userReducer
  );

  const [isTestEnv, setIsTestEnv] = useState(false);
  const [isLocalHost, setIsLocalHost] = useState(false);

  useEffect(() => {
    let isMounted = true;

    if (fdot.process.env.APP_ENVIRONMENT !== "production") {
      setIsTestEnv(true);
    }

    configApi
      .getClientConfig()
      .then(async (clientConfig: IClientConfig) => {
        const localHost = clientConfig.host.includes("localhost");
        if (isMounted) setIsLocalHost(localHost);
        if (!localHost && clientConfig.isInternal && !isAuthenticated) {
          const url = window.location.href;
          await localforage.setItem("deep-link-redirect-url", url);
          window.location.href = `${fdot.process.env.BACKEND_SERVER_HOST}/login`;
        }
      })
      .catch((error) => {
        appInsights.trackException({
          error,
          id: "Fetching client config",
          severityLevel: SeverityLevel.Critical,
        });
      });
    return () => {
      isMounted = false;
    };
  }, []);

  const toggle = () => setIsExpanded(!isExpanded);
  const isImpersonating = user && user.srsId !== user.actualUserSrsId;

  const getLoginOrSignOutLink = () => {
    const loginUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/login`;
    const logoutUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/logout`;

    return isAuthenticated ? (
      <a
        className="nav-link"
        title="Sign Out"
        href={logoutUrl}
        style={headerTextStyle}
      >
        Sign Out
      </a>
    ) : (
      isLocalHost && (
        <a
          className="nav-link"
          title="FDOT Staff Login Here"
          href={loginUrl}
          style={headerTextStyle}
        >
          Login
        </a>
      )
    );
  };

  const getLoggedInUserDisplay = () => {
    return isAuthenticated ? (
      <>
        <div className="d-none d-xl-block" style={headerTextStyle}>
          {isImpersonating ? (
            <span
              className="mx-2"
              title={`Impersonating: ${user!.displayName}`}
            >
              Impersonating: {user!.displayName}
            </span>
          ) : (
            <span className="mx-2" title={`Welcome: ${user!.displayName}`}>
              Welcome: {user!.displayName}
            </span>
          )}
        </div>
      </>
    ) : (
      ""
    );
  };

  const getUnAuthenticatedOptions = () => {
    return (
      <>
        <NavItem>
          <NavLink to="/Forms" tag={Link} title="Forms" className="text-white">
            Forms
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to="/Procedures"
            tag={Link}
            title="Procedures"
            className="text-white"
          >
            Procedures
          </NavLink>
        </NavItem>
      </>
    );
  };

  const getAuthenticatedHelp = () => {
    return (
      <>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret className="text-white" title="Help">
            Help
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              as={NavLink}
              to="/ContactUs"
              tag={Link}
              style={dropDownMenuItemStyle}
            >
              Contact Us
            </DropdownItem>

            <DropdownItem
              style={dropDownMenuItemStyle}
              tag="a"
              target="_blank"
              rel="noopener noreferrer"
              href={"https://fldot.sharepoint.com/sites/CO-ODO/SitePages/Procedural-Document-Library-Resources.aspx"}
            >
              PDL Resources
            </DropdownItem>
            {/*
            <DropdownItem
              style={dropDownMenuItemStyle}
              tag="a"
              target="_blank"
              rel="noopener noreferrer"
              href={
                "http://fdotsp.dot.state.fl.us/sites/TransportationSupport/OrgDev/PPM/SitePages/Home.aspx"
              }
            >
              Policy & Process Management
            </DropdownItem>
            <DropdownItem
              style={dropDownMenuItemStyle}
              tag="a"
              target="_blank"
              rel="noopener noreferrer"
              href={
                "http://fdotsp.dot.state.fl.us/sites/TransportationSupport/OrgDev/PPM/Lists/Request%20for%20Form%20Action/NewForm.aspx"
              }
            >
              Request for Form Action (Document Owners ONLY)
            </DropdownItem>
            <DropdownItem
              style={dropDownMenuItemStyle}
              tag="a"
              target="_blank"
              rel="noopener noreferrer"
              href={
                "http://fdotsp.dot.state.fl.us/sites/TransportationSupport/OrgDev/PPM/Samples/Forms/AllItems.aspx"
              }
            >
              How to develop a policy, policy and procedure, or manual
            </DropdownItem> */}
          </DropdownMenu>
        </UncontrolledDropdown>
      </>
    );
  };

  const getHelp = () => {
    return (
      <>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret className="text-white" title="Help">
            Help
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              as={NavLink}
              to="/ContactUs"
              tag={Link}
              style={dropDownMenuItemStyle}
            >
              Contact Us
            </DropdownItem>
            {/*
            <DropdownItem
              style={dropDownMenuItemStyle}
              tag="a"
              target="_blank"
              rel="noopener noreferrer"
              href={
                "http://fdotsp.dot.state.fl.us/sites/TransportationSupport/OrgDev/PPM/SitePages/Home.aspx"
              }
            >
              Policy & Process Management
            </DropdownItem>
            <DropdownItem
              style={dropDownMenuItemStyle}
              tag="a"
              target="_blank"
              rel="noopener noreferrer"
              href={
                "http://fdotsp.dot.state.fl.us/sites/TransportationSupport/OrgDev/PPM/Lists/Request%20for%20Form%20Action/NewForm.aspx"
              }
            >
              Request for Form Action (Document Owners ONLY)
            </DropdownItem>
            <DropdownItem
              style={dropDownMenuItemStyle}
              tag="a"
              target="_blank"
              rel="noopener noreferrer"
              href={
                "http://fdotsp.dot.state.fl.us/sites/TransportationSupport/OrgDev/PPM/Samples/Forms/AllItems.aspx"
              }
            >
              How to develop a policy, policy and procedure, or manual
            </DropdownItem> */}
          </DropdownMenu>
        </UncontrolledDropdown>
      </>
    );
  };

  const getAuthenticatedOptions = () => {
    if (user !== undefined && isAuthenticated) {
      return (
        <>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle
              nav
              caret
              className="text-white"
              title="Customer Portal"
            >
              Customer Portal
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                style={dropDownMenuItemStyle}
                tag={Link}
                to={"/Reviews"}
              >
                Procedural Document Review Dashboard
              </DropdownItem>
              <DropdownItem
                style={dropDownMenuItemStyle}
                tag={Link}
                to={"/request-form-actions"}
                title="Request for Form Action"
              >
                Request for Form Action
              </DropdownItem>
              <DropdownItem
                style={dropDownMenuItemStyle}
                tag={Link}
                to={"/document-contact-changes"}
                rel="noopener noreferrer"
                title="Document Contact Change"
              >
                Document Contact Change
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </>
      );
    }
  };

  const getAdminOptions = () => {
    if (user !== undefined && isAuthenticated && user.isAdmin) {
      const mustAuhtenticateDocusign =
        user?.docusignUser?.docuSignAuthenticationRequired === true;

      return (
        <>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret className="text-white" title="Admin">
              Admin
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                style={dropDownMenuItemStyle}
                tag={Link}
                to={"/Forms/ContactFormsOwners"}
                title="Contact Document Owners"
              >
                Contact Document Owners
              </DropdownItem>
              <DropdownItem
                style={dropDownMenuItemStyle}
                tag={Link}
                to={"/Procedures/MasterDataReport"}
                title="Master Data Report"
              >
                Procedural Master Data Report
              </DropdownItem>
              {/* <DropdownItem
                style={dropDownMenuItemStyle}
                tag={Link}
                to={
                  mustAuhtenticateDocusign
                    ? "/Forms/DocuSign/Login"
                    : "/Forms/Document/Sign"
                }
                title="Sign Document"
              >
                Sign to DocuSign
              </DropdownItem> */}
            </DropdownMenu>
          </UncontrolledDropdown>
        </>
      );
    } else {
      return null;
    }
  };

  const getFeatureOptions = () => {
    if (isAuthenticated) {
      return (
        <>
          {getUnAuthenticatedOptions()}
          {getAuthenticatedOptions()}
          {getAdminOptions()}
          {getAuthenticatedHelp()}
          {getTestEnvironmentOptions()}
        </>
      );
    } else {
      return (
        <>
          {getUnAuthenticatedOptions()}
          {getHelp()}
        </>
      );
    }
  };

  const getTestEnvironmentOptions = () => {
    if (fdot.process.env.APP_ENVIRONMENT !== "production") {
      return (
        <>
          <NavItem>
            <NavLink
              tag={Link}
              to={"/Impersonate"}
              title="Impersonation"
              className="text-white"
            >
              Impersonation
            </NavLink>
          </NavItem>
          {/* <DropdownItem
            style={dropDownMenuItemStyle}
            tag={Link}
            to={"/Impersonate"}
            title="Impersonation"
          >
            Impersonation
          </DropdownItem> */}
        </>
      );
    }
    return null;
  };

  const getNavbar = () => {
    return (
      <div>
        <Navbar color="dark" dark expand="lg">
          <div className="d-none d-md-block">
            <NavbarBrand href="/" style={brandStyle}>
              <img
                src={FDOTLogo}
                className="d-inline-block align-top"
                height="40"
                width="90"
                alt="FDOT Logo"
                title="Florida Department of Transportation"
              />
              <span
                title="Procedural Document Library"
                style={{ paddingLeft: "10px" }}
              >
                Procedural Document Library
              </span>
            </NavbarBrand>
          </div>
          <div className="d-block d-md-none">
            <NavbarBrand href="/" style={brandStyle}>
              <img
                src={FDOTLogo}
                className="d-inline-block align-top"
                height="40"
                width="90"
                alt="FDOT Logo"
                title="Florida Department of Transportation"
              />
              <span
                title="Procedural Document Library"
                style={{ paddingLeft: "10px" }}
              >
                PDL
              </span>
            </NavbarBrand>
          </div>

          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isExpanded} navbar>
            <Nav navbar>{getFeatureOptions()}</Nav>
            <Nav className="ms-auto">
              <NavItem>{getLoggedInUserDisplay()}</NavItem>
              <NavItem>{getLoginOrSignOutLink()}</NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        {isTestEnv && (
          <div className="container-fluid bg-warning">
            <div className="row d-flex justify-content-center fw-bold">
              Warning: This is a Test Environment!
            </div>
          </div>
        )}
      </div>
    );
  };

  return getNavbar();
};

export default Header;
